import { render, staticRenderFns } from "./RegisterFormInfoGuest.vue?vue&type=template&id=60a81728"
import script from "./RegisterFormInfoGuest.vue?vue&type=script&lang=js"
export * from "./RegisterFormInfoGuest.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoogleAutoComplete: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/GoogleAutoComplete.vue').default})
