import { render, staticRenderFns } from "./index.vue?vue&type=template&id=73d2714c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderBanner: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/SliderBanner.vue').default,DoubleSquareCard: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/DoubleSquareCard.vue').default,BlockImageCenter: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/BlockImageCenter.vue').default,OurSelection: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/OurSelection.vue').default,OurShops: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/OurShops.vue').default,FollowUs: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/FollowUs.vue').default})
