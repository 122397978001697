import { render, staticRenderFns } from "./CartEmptyError.vue?vue&type=template&id=e21fe158&scoped=true"
import script from "./CartEmptyError.vue?vue&type=script&lang=js"
export * from "./CartEmptyError.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e21fe158",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContinueMyShoppingLink: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/ContinueMyShoppingLink.vue').default})
