import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6f82062f&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=6f82062f&prod&lang=scss"
import style1 from "./index.vue?vue&type=style&index=1&id=6f82062f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f82062f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Rating: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/Rating.vue').default})
