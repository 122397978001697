import { render, staticRenderFns } from "./StepPayment.vue?vue&type=template&id=4b611dff"
import script from "./StepPayment.vue?vue&type=script&lang=js"
export * from "./StepPayment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContinueMyShopping: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/ContinueMyShopping.vue').default,MiniCart: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/MiniCart.vue').default})
