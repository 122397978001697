import { render, staticRenderFns } from "./NuxtLogo.vue?vue&type=template&id=fde1ff6a"
var script = {}
import style0 from "./NuxtLogo.vue?vue&type=style&index=0&id=fde1ff6a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports