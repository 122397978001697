import { render, staticRenderFns } from "./DefaultLayout.vue?vue&type=template&id=1e518edc"
import script from "./DefaultLayout.vue?vue&type=script&lang=js"
export * from "./DefaultLayout.vue?vue&type=script&lang=js"
import style0 from "./DefaultLayout.vue?vue&type=style&index=0&id=1e518edc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/Menu.vue').default,NewsLetterModal: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/NewsLetterModal.vue').default,LocalisationDialog: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/LocalisationDialog.vue').default,BlockSearch: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/BlockSearch.vue').default,FooterApp: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/FooterApp.vue').default,AbandonedVisit: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/AbandonedVisit.vue').default,Loading: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/Loading.vue').default,CookiesBar: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/CookiesBar.vue').default})
