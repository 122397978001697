import { render, staticRenderFns } from "./tunnel.vue?vue&type=template&id=080ef690"
import script from "./tunnel.vue?vue&type=script&lang=js"
export * from "./tunnel.vue?vue&type=script&lang=js"
import style0 from "./tunnel.vue?vue&type=style&index=0&id=080ef690&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderTunnel: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/HeaderTunnel.vue').default,FooterTunnel: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/FooterTunnel.vue').default,Loading: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/Loading.vue').default})
