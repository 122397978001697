import { render, staticRenderFns } from "./ProductCardTop.vue?vue&type=template&id=4b36028f&scoped=true"
import script from "./ProductCardTop.vue?vue&type=script&lang=js"
export * from "./ProductCardTop.vue?vue&type=script&lang=js"
import style0 from "./ProductCardTop.vue?vue&type=style&index=0&id=4b36028f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b36028f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageProduct: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/ImageProduct.vue').default})
