import { render, staticRenderFns } from "./ProductInformations.vue?vue&type=template&id=2b5fb2ee&scoped=true"
import script from "./ProductInformations.vue?vue&type=script&lang=js"
export * from "./ProductInformations.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5fb2ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PreorderSlide: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/PreorderSlide.vue').default,ColorsProductV2: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/ColorsProductV2.vue').default})
