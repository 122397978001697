import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=0a91c92c"
import script from "./Button.vue?vue&type=script&lang=js"
export * from "./Button.vue?vue&type=script&lang=js"
import style0 from "./Button.vue?vue&type=style&index=0&id=0a91c92c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports