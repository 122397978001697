import { render, staticRenderFns } from "./OurSelection.vue?vue&type=template&id=7eb6ae41"
import script from "./OurSelection.vue?vue&type=script&lang=js"
export * from "./OurSelection.vue?vue&type=script&lang=js"
import style0 from "./OurSelection.vue?vue&type=style&index=0&id=7eb6ae41&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockSlider2: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/BlockSlider2.vue').default})
