import { render, staticRenderFns } from "./CustomerServiceProvider.vue?vue&type=template&id=5032bd38&scoped=true"
import script from "./CustomerServiceProvider.vue?vue&type=script&lang=js"
export * from "./CustomerServiceProvider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5032bd38",
  null
  
)

export default component.exports