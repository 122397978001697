import { render, staticRenderFns } from "./FooterAppTop.vue?vue&type=template&id=fa40960e&scoped=true"
import script from "./FooterAppTop.vue?vue&type=script&lang=js"
export * from "./FooterAppTop.vue?vue&type=script&lang=js"
import style0 from "./FooterAppTop.vue?vue&type=style&index=0&id=fa40960e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa40960e",
  null
  
)

export default component.exports